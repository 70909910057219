import { InjectionToken } from '@angular/core';
import { FuseNavigation } from '@fuse/types';
import { IRouteInfo } from '@common/shared/models/route-info';
import { StatisticsActivities } from '@common/models/statistic-activities';
import { propertyStringPathFactory } from '@common/i18n/translation-helpers';
import { ILocaleData } from '@common/i18n/ilocale';

export interface IPayloadItem extends IRouteInfo {
  breadcrumb?: string;
  displayBackButton?: boolean;
  pageTrackingKey?: string;
}

export interface INavigationItem {
  id: string;
  title: string;
  translate?: string;
  type: 'item' | 'group' | 'collapsable';
  url: string;
  hidden?: boolean;
  classes?: string;
  isComing?: boolean;
  isActionable?: boolean;
  payload?: IPayloadItem;
  groupType?: string;
}

export interface INavigationConfig {
  root: INavigationItem;
  register: INavigationItem;
  forgot_password: INavigationItem;
  reset_password: INavigationItem;
  nutrition?: INavigationItem;
  testing?: INavigationItem;
  mindset?: INavigationItem;
  treatment?: INavigationItem;
  recovery?: INavigationItem;
  physical_therapy?: INavigationItem;
  shop?: INavigationItem;
  edit_profile: INavigationItem;
  sign_out: INavigationItem;
  about_spl: INavigationItem;
  select_profile: INavigationItem;
  schedule_in_studio_single_session: INavigationItem;
  check_unique_id?: INavigationItem;
}

export interface ICommonNavigationConfig {
  dashboard: INavigationItem;
  training: INavigationItem;
  training_session: INavigationItem;
  exercise_program_training_session: INavigationItem;
  reschedule_in_studio_session: INavigationItem;
  training_details: INavigationItem;
  great_workout: INavigationItem;
  reschedule_in_studio_training: INavigationItem;
  reschedule_in_studio_training_success: INavigationItem;
  schedule_in_studio_recurring_session: INavigationItem;
  schedule_in_studio_recurring_session_success: INavigationItem;
  schedule_assessment: INavigationItem;
  testing_assessment: INavigationItem;
  testing_assessment_history: INavigationItem;
  testing_assessment_summary: INavigationItem;
  notAuthorized: INavigationItem;
  preferences: INavigationItem;
  in_take: INavigationItem;
  change_password: INavigationItem;
  switch_profile: INavigationItem;
}

export const translationFiels = propertyStringPathFactory<ILocaleData>();

export const commonNavigationConfig: ICommonNavigationConfig = {
  dashboard: {
    id: 'dashboard',
    title: 'Dashboard',
    translate: translationFiels('NAV.DASHBOARD'),
    type: 'item',
    url: '/dashboard',
    payload: {
      breadcrumb: translationFiels('NAV.DASHBOARD'),
      pageTrackingKey: StatisticsActivities.PAGE_DASHBOARD,
    },
    groupType: 'general',
  },
  training: {
    id: 'training',
    title: 'Training',
    translate: translationFiels('NAV.TRAINING'),
    type: 'item',
    url: '/training',
    payload: {
      breadcrumb: translationFiels('NAV.TRAINING_OVERVIEW'),
      pageTrackingKey: StatisticsActivities.PAGE_TRAINING,
    },
    groupType: 'general',
  },
  training_session: {
    id: 'training_session',
    title: 'Training Session',
    translate: translationFiels('NAV.TRAINING_SESSION'),
    type: 'item',
    url: '/training/training-session',
  },
  exercise_program_training_session: {
    id: 'exercise_program_training_session',
    title: 'Training Overview',
    translate: translationFiels('NAV.TRAINING_OVERVIEW'),
    type: 'item',
    url: '/training/exercise-program-training-session',
  },
  reschedule_in_studio_session: {
    id: 'reschedule_in_studio_session',
    title: 'Reschedule In Studio Session',
    translate: translationFiels('NAV.RESCHEDULE_IN_STUDIO_SESSION'),
    type: 'item',
    url: '/training/reschedule-in-studio-session',
    payload: {
      breadcrumb: translationFiels('NAV.RESCHEDULE_IN_STUDIO_SESSION'),
    },
    hidden: true,
  },
  training_details: {
    id: 'training_details',
    title: 'Training Details',
    translate: translationFiels('NAV.TRAINING_DETAILS'),
    type: 'item',
    url: '/training/training-details',
    payload: {
      breadcrumb: '<% date %> %EventType% Details',
    },
    hidden: true,
  },
  great_workout: {
    id: 'great_workout',
    title: 'Great Workout',
    translate: translationFiels('NAV.GREAT_WORKOUT'),
    type: 'item',
    url: '/training/great-workout',
    payload: {
      displayBackButton: false,
    },
  },
  reschedule_in_studio_training: {
    id: 'reschedule_in_studio_training',
    title: 'Reschedule In Studio Training',
    translate: translationFiels('NAV.RESCHEDULE_IN_STUDIO_TRAINING'),
    type: 'item',
    url: '/training/reschedule-in-studio-training',
    payload: {
      breadcrumb: 'Reschedule Session',
    },
    hidden: true,
  },
  reschedule_in_studio_training_success: {
    id: 'reschedule_in_studio_training_success',
    title: 'Reschedule In Studio Training',
    translate: translationFiels('NAV.RESCHEDULE_IN_STUDIO_TRAINING'),
    type: 'item',
    url: '/training/reschedule-in-studio-training-success',
    payload: {
      breadcrumb: 'Reschedule Session',
      displayBackButton: false,
    },
    hidden: true,
  },
  schedule_in_studio_recurring_session: {
    id: 'schedule_in_studio_recurring_session',
    title: 'Schedule In-Studio Training',
    translate: translationFiels('NAV.SCHEDULE_IN_STUDIO_TRAINING'),
    type: 'item',
    url: '/training/in-studio-recurring-session',
    payload: {
      breadcrumb: translationFiels('NAV.SCHEDULE_IN_STUDIO_TRAINING'),
    },
    hidden: true,
  },
  schedule_in_studio_recurring_session_success: {
    id: 'schedule_in_studio_recurring_session_success',
    title: 'Schedule In-Studio Training',
    translate: translationFiels('NAV.SCHEDULE_IN_STUDIO_TRAINING'),
    type: 'item',
    url: '/training/in-studio-recurring-session-success',
    payload: {
      breadcrumb: translationFiels('NAV.SCHEDULE_IN_STUDIO_TRAINING'),
      displayBackButton: false,
    },
    hidden: true,
  },
  schedule_assessment: {
    id: 'schedule_assessment',
    title: 'Schedule Assessment',
    translate: translationFiels('NAV.SCHEDULE_ASSESSMENT'),
    type: 'item',
    url: '/training/assessment',
    payload: {
      breadcrumb: translationFiels('NAV.SCHEDULE_ASSESSMENT'),
    },
    hidden: true,
  },
  testing_assessment: {
    id: 'testing_assessment',
    title: 'Testing',
    translate: translationFiels('NAV.TESTING'),
    type: 'item',
    url: '/assessments',
    payload: {
      breadcrumb: translationFiels('NAV.TESTING_OVERVIEW'),
      pageTrackingKey: StatisticsActivities.PAGE_TESTING,
    },
    groupType: 'general',
  },
  testing_assessment_history: {
    id: 'testing_assessment_history',
    title: 'Testing Assessment History',
    translate: translationFiels('NAV.TESTING_ASSESSMENT_HISTORY'),
    type: 'item',
    url: '/assessments/history',
    payload: {
      breadcrumb: translationFiels('NAV.TESTING_OVERVIEW'),
    },
    hidden: true,
  },
  testing_assessment_summary: {
    id: 'testing_assessment_summary',
    title: 'Test Summary',
    translate: translationFiels('NAV.TEST_SUMMARY'),
    type: 'item',
    url: '/assessments',
    payload: {
      breadcrumb: translationFiels('NAV.TESTING_HISTORY'),
    },
    hidden: true,
  },
  notAuthorized: {
    id: 'notAuthorized',
    title: 'Not Authorized',
    translate: translationFiels('NAV.NOT_AUTHORIZED'),
    type: 'item',
    url: '/errors/error-401',
    hidden: true,
  },
  preferences: {
    id: 'preferences',
    title: 'Preferences',
    translate: translationFiels('NAV.PREFERENCES'),
    type: 'item',
    url: '/user-preferences',
    groupType: 'profile',
    payload: {
      pageTrackingKey: StatisticsActivities.PAGE_PREFERENCIES,
    },
  },
  in_take: {
    id: 'in_take',
    title: 'Edit Profile',
    translate: translationFiels('NAV.EDIT_PROFILE'),
    type: 'item',
    url: 'in-take',
    hidden: true,
    payload: {
      pageTrackingKey: StatisticsActivities.PAGE_EDIT_PROFILE,
    },
  },
  change_password: {
    id: 'change_password',
    title: 'Change Password',
    translate: translationFiels('NAV.CHANGE_PASSWORD'),
    type: 'item',
    url: 'change-password',
    groupType: 'profile',
    payload: {
      pageTrackingKey: StatisticsActivities.PAGE_CHANGE_PASSWORD,
    },
  },
  switch_profile: {
    id: 'switch_profile',
    title: 'Switch Profile',
    translate: translationFiels('NAV.SWITCH_PROFILE'),
    type: 'item',
    url: undefined,
    isActionable: true,
    groupType: 'profile',
    payload: {
      pageTrackingKey: StatisticsActivities.PAGE_SWITCH_PROFILE,
    },
  },
};

export const NAVIGATION = new InjectionToken<FuseNavigation[]>('navigation');
export const NAVIGATION_CONFIG = new InjectionToken<INavigationConfig>(
  'navigation_config',
);
